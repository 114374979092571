.pulsate-border {
	position: relative;
	display: inline-flex;
}

.pulsate-border .pulse {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	opacity: 1;
	position: absolute;
	background: transparent;
	transition: box-shadow 0.5s ease;
}

.pulsate-border.recording .pulse {
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 128, 255, 0.4);
	}
	25% {
		box-shadow: 0 0 0 4px rgba(0, 128, 255, 0.25);
	}
	50% {
		box-shadow: 0 0 0 0 rgba(0, 128, 255, 0.25);
	}
	75% {
		box-shadow: 0 0 0 8px rgba(0, 128, 255, 0.2);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 128, 255, 0.25);
	}
}
